import * as React from 'react'

import { FAQs } from '../../features/FAQ'
import { Layout } from '../../components/Layout'
import { SEO } from '../../components/SEO'
import { useFAQQuery } from '../../features/FAQ/FAQs/useFAQQuery'

const FAQPage = () => {
  const {
    wpPage: {
      title,
      ACF_FAQPage: { faq },
    },
  } = useFAQQuery()

  return (
    <Layout>
      <SEO title="FAQ" />
      <main>
        <FAQs title={title} faqs={faq} />
      </main>
    </Layout>
  )
}

export default FAQPage
