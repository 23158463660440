import * as React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
  title: string
}

export const SEO = ({ title }: Props) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
)
