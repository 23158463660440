import * as React from 'react'

import { FAQ } from '../../../components/FAQ'

type Props = {
  title: string
  faqs: {
    answer: string
    question: string
  }[]
}

export const FAQs = ({ faqs, title }: Props) => (
  <div className="w-full px-10 md:px-0 md:w-6/12 mx-auto">
    <h1 className="text-5xl md:text-6xl text-center">{title}</h1>
    <div className="divide-y">
      {faqs.map((faq, index) => (
        <FAQ key={index} answer={faq.answer} question={faq.question} />
      ))}
    </div>
  </div>
)
