import { graphql, useStaticQuery } from 'gatsby'

type FAQQueryResponse = {
  wpPage: {
    id: string
    title: string
    ACF_FAQPage: {
      faq: {
        question: string
        answer: string
      }[]
    }
  }
}

export const useFAQQuery = () => {
  const data = useStaticQuery<FAQQueryResponse>(graphql`
    query GET_FAQ {
      wpPage(slug: { eq: "faq" }) {
        id
        title
        ACF_FAQPage {
          faq {
            question
            answer
          }
        }
      }
    }
  `)

  return data
}
